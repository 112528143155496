import React from "react";
import { useState, useEffect } from "react";
import "./page.css";

//a component that renders a footer with important links and social media icons
function Footer() {

    return (
        <div className="footer">
                <div className="footerContent">

                        <div className="rightsRes" onClick={()=>{
                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth',
                            });
                        }}>
                            <p className="whiteCredentials">@ 2022 Splxsh.com</p>
                        </div>

                        <div className="footerSocials">
                            <a  href="https://www.instagram.com/roman.founder/">
                                <img className="footerIcon" src="/insta.png" alt="instagram icon" />
                            </a>

                            <a  href="https://twitter.com/romanfounder">
                                <img className="footerIcon" src="/twitter.png" alt="twitter icon" />
                            </a>
                        </div>
                        
                        {/* <div className="footerLinks">
                            <a className="footerLinks" href="/privacy-policy">Privacy Policy</a>
                            <a className="footerLinks" href="/terms-of-service">Terms of Service</a>
                        </div> */}
                </div>
        </div>
    );
}

export default Footer;