import React from "react";
import { useState, useEffect } from "react";
import "./page.css";
import "./page.scss";
import ImageGallery from 'react-image-gallery';
import Showcase from "./Showcase";
import Footer from "./Footer";



function Page(props) {
  const [page, setPage] = useState(props.page);
  const [pageContent, setPageContent] = useState(props.pageContent);

  // array of objects with image title, path, description
  const images = [
    {
      title: "Vanilla Splash",
      folder: "vanillasplash",
    },
    {
      title: "Candy Fountain",
      folder: "candyfountain",
    },
    {
      title: "Pastel Tulip",
      folder: "pasteltulip",
    },
    {
      title: "Sugar Root",
      folder: "sugarroot",
    },
    {
      title: "Sweet Twins",
      folder: "sweettwins",
    },
    {
      title: "Cream Cone",
      folder: "creamcone",
    },
    {
      title: "Cloud Twist",
      folder: "cloudtwist",
    },
    {
      title: "Gee Reveal",
      folder: "geereveal",
    },
    {
      title: "Air Puff",
      folder: "airpuff",
    },
    {
      title: "Cozy Spice",
      folder: "cozyspice",
    },
    {
      title: "Misty Dream",
      folder: "mistydream",
    },
    {
      title: "Clean Goo",
      folder: "cleangoo",
    },
    {
      title: "Ambient Ray",
      folder: "ambientray",
    },
    {
      title: "Space Orchid",
      folder: "spaceorchid",
    },
    {
      title: "Marble Wale",
      folder: "marblewale",
    },
    {
      title: "Coral Paste",
      folder: "coralpaste",
    },
    {
      title: "Soft Ice",
      folder: "softice",
    },
    {
      title: "Time Paint",
      folder: "timepaint",
    },
  ];
      
      


  
  // function that maps folders in /images to image gallery objects
  const mapImages = (folder) => {
    let imageList = [];
    for (let i = 1; i < 9; i++) {
      imageList.push({
        original: `/images/${folder}/original/image${i}.jpg`,
        thumbnail: `/images/${folder}/thumbnail/image${i}.jpg`,
      });
    }
    return imageList;
  };

  function randomize(a, b) {
    return Math.random() - 0.5;
  }



  useEffect(() => {
    setPage(props.type);
    setPageContent(props.pageContent);
    
  }, [props.page, props.pageContent]);

  return (
    <div className="page">
        <div id="particle-container">
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
        </div>

        
        

        <p className="big">UNLIMITED ART</p>
        <p className="mid" style={{marginTop:"-1.3em"}}>for YOUR online content</p>

        <div id="nameheader">

            <div id="instagram">
                <img id="instagramimage" src="/splxsh.jpg"/>
            </div>
            <div>
            <a href="https://www.instagram.com/roman.founder/" style={{textDecoration:"none"}} target="_blank" rel="noreferrer">
                <p className="mid"> Roman</p>
            </a>
            </div>
        
        </div>

        <div className="drop" id="drop1"></div>
        <div className="drop" id="drop2"></div>


    <div className="panel">
      <button class="cta-button third">
        <a href="https://promptbase.com/prompt/splashy-background-artworks" style={{"textDecoration": "none"}}>
          Get this prompt on PromptBase
        </a>
      </button>
    </div>

  {

    images.sort(randomize).map((image, index) => {
      if (index % 3 === 0) {
        return (
          <div className="panel" key={index}>
            <Showcase title={images[index].title} images={mapImages(image.folder)} />
            <Showcase title={images[index + 1].title} images={mapImages(images[index + 1].folder)} />
            <Showcase title={images[index + 2].title} images={mapImages(images[index + 2].folder)} />
          </div>
        );
      }
    })
  }

<div className="listDiv">
  <div className="panel">
    <ol class="gradient-list">  
      <li><strong>Customization:</strong> <br></br>Dall-E allows users to create unique, personalized backgrounds that are tailored to their specific tastes and preferences. This can be especially useful for artists and designers looking to create backgrounds for their work that stand out from the crowd.</li>
      <li><strong>Creativity:</strong> <br></br>Dall-E's generative capabilities allow users to explore new and creative ideas for their backgrounds, pushing the boundaries of what is possible with traditional art creation methods.</li>
      <li><strong>Time-saving:</strong> <br></br>Using Dall-E to create custom backgrounds can save time and effort compared to traditional art creation methods, especially for those who are not skilled in art or design.</li>
      <li><strong>Accessibility:</strong> <br></br>Dall-E is accessible to users with a wide range of skill levels, making it easy for anyone to create custom background art without the need for specialized training or knowledge.</li>
    </ol>
  </div>
  <div className="panel">
    <button class="cta-button third">
      <a href="https://promptbase.com/prompt/splashy-background-artworks" style={{"textDecoration": "none"}}>
        Get this prompt on PromptBase
      </a>
    </button>
  </div>
</div>


       
        <div className="drop" id="drop4"></div>
        <div className="drop" id="drop5"></div>


        
        
    </div>
    
  );
}

export default Page;