import './App.css';
import { useEffect } from 'react';
import PageMobile from "./components/PageMobile";
import Page from "./components/Page";
import PrivacyPolicy from './components/PrivacyPolicy';
import Terms from './components/Terms';
import Footer from './components/Footer';
import {
  BrowserRouter,
  Routes,
  Route,
  Link
} from "react-router-dom";

function App() {

  return (
    <Page />
  );
}

export default App;
