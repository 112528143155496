import React from 'react';
import { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.css';
import App from './App';
import Footer from './components/Footer';
import PrivacyPolicy from './components/PrivacyPolicy';
import Terms from './components/Terms';
import {
    BrowserRouter,
    Routes,
    Route,
    Link
  } from "react-router-dom";

  const Routing = () => {
      
    return(
        <BrowserRouter>
            <div className="App">
                <Routes>
                <Route path="/" element={(window.screen.width < 1200) ? <App type={"random"} /> : <App type={"random"} />} />
                <Route path="/privacy-policy" element={(window.screen.width < 1200) ? <PrivacyPolicy /> : <PrivacyPolicy />} />
                <Route path="/terms-of-service" element={(window.screen.width < 1200) ? <Terms /> : <Terms />} />
                </Routes>
            
                <Footer />
            </div>
        </BrowserRouter>
    )
  }

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Routing />
);


