import React from "react";
import { useState, useEffect } from "react";
import "./page.css";
import { saveAs } from 'file-saver';



function Options({ optionsActive, hoverOffHandler, galleryState, imageList }) {

    

    const downloadImage = (version) => {
        
        saveAs(imageList[galleryState.current?.state.currentIndex].original.replace("thumbnail", version), Date.now() + '_splash.jpg');
        }
    

    return (
        <div>
        { optionsActive ? 
            <div className="optionsField" onMouseLeave={()=>{hoverOffHandler()}}>    
                    <p className="downloadText">Download</p>
                    
                    <a className="downloadButton" key="original" onClick={(event) => {
                        downloadImage("original");
                        event.preventDefault();
                    }}>
                        <p style={{display:"inline"}}><strong>Small</strong> (1024x1024)</p>
                    </a>

                    <a className="downloadButton" key="highres" onClick={(event) => {
                        downloadImage("highres");
                        event.preventDefault();
                    }}>
                        <p style={{display:"inline"}}><strong>Medium</strong> (2064x2064)</p>
                    </a>

                    <a className="downloadButton" key="uhighres" onClick={(event) => {
                        downloadImage("uhighres");
                        event.preventDefault();
                    }}>
                        <p style={{display:"inline"}}><strong>Large</strong> (4096x4096)</p>
                    </a>

            </div> : "" } 
        </div>
    );



}

export default Options;