import React, { Children } from "react";
import { useState, useEffect, useRef } from "react";
import "./page.css";
import ImageGallery from 'react-image-gallery';
import Options from "./Options";

/* import  { Dropdown, Button } from 'antd';
import 'antd/dist/antd.css';
import { DownOutlined, UserOutlined } from '@ant-design/icons'; */

  function Showcase(props) {

    const [optionsActive, setOptionsActive] = useState(false);
    const [galleryProps, setGalleryProps] = useState({});
    const [scrolled, setScrolled] = useState(false);
    const galleryRef = useRef(null);



    const handleHoverOn = () => {
        //console.log("options active: " + optionsActive);
        //console.log("scrolled: " + scrolled)
        if (scrolled) {
            setScrolled(false);
        } else {
            if (!optionsActive){
                setOptionsActive(true);
                //console.log("options now set to true: ", optionsActive)
            }
        }
    }

    const handleHoverOff = () => {
        setTimeout(()=>{
            //console.log("options active: ", optionsActive);
            if (optionsActive){
                setOptionsActive(false);
                //console.log("options now set to false: ", optionsActive)
            }
        }, 0);
    }

    const handleScrollTouch = () => {
        setTimeout(()=>{
            setScrolled(true);
            //console.log("scrolled now set to true: ", scrolled)
        }, 0);
    }

    /* const items = [
        {
          label: '1st menu item',
          key: '1',
        },
        {
          label: '2nd menu item',
          key: '2',
        },
        {
          label: '3rd menu item',
          key: '3',
        },
      ]; */

    return (
        <div className="showcaseElement">
            <div className="labelBox">
                <div className="freeBox">
                    <p className="freeText">Free</p>
                </div>
                <p className="labelText">{props.title}</p>
            </div>
            <div className="optionsPosition">
                <Options optionsActive={optionsActive} hoverOffHandler={handleHoverOff} galleryState={galleryRef} imageList={props.images} />
            </div>
            <div className="gallery">
                    <ImageGallery 
                        items={props.images}  
                        slideDuration={200}
                        showPlayButton={false}
                        showFullscreenButton={false}
                        showNav={false}
                        slideOnThumbnailOver={true}
                        onMouseOver={handleHoverOn}
                        ref={galleryRef}
                        onTouchEnd={handleHoverOn}
                        onTouchMove={handleScrollTouch}
                    />
            </div>

            {/* <div className="dropdownDiv">
            <Dropdown.Button menu={{items}} icon={<DownOutlined />} >
                Download
            </Dropdown.Button>

            </div> */}
        </div>
    );
  }

  export default Showcase;